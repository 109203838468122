import React, {Component} from 'react'
import injectSheet from 'react-jss'

const styles = {
	title: {
		color: 'white',
		fontFamily: 'Montserrat',
		marginBottom: 4
	},
	titleBar: {
		backgroundColor: '#F37B1D',
		height: '4px',
		width: '48px'
	},
  spacer: {
		height: '2em',
		'@media (max-width: 767px)': {
			height: '1em'
		}
	},
	dark: {
		color: 'black'
	},
	small: {
		fontSize: '1.35em'
	},
	smallSpacer: {
		height: '1em'
	}
}
class HeaderText extends Component {
  render() {
    const {classes, children, dark, small, noSpacer} = this.props
    return(
      <div>
        <h2 className={classes.title + " " + (dark ? classes.dark : "") + " " + (small ? classes.small : "")}>{children}</h2>
        <div className={classes.titleBar}></div>
				<div className={(noSpacer ? '' : (small ? classes.smallSpacer : classes.spacer))}></div>
      </div>
    )
  }
}

export default injectSheet(styles)(HeaderText)
