import React, {Component} from 'react';
import Link from 'gatsby-link';
import injectSheet from 'react-jss';
import siteLogo from '../images/logos/sitelogo.png';

const styles = {
	footer: {
		backgroundColor: '#222222',
		position: 'relative',
		padding: '1em 1em 1em 2em',
		height: '170px',
		width: '100%',
		display: 'flex',
		flexFlow: 'row',
		minWidth: '240px',
		border: '2px solid #222222',
		'@media (max-width: 767px)': {
			justifyContent: 'space-between',
			height: 'auto'
		},
		'@media (max-width: 280px)': {
			padding: '1em'
		}
	},
	footerLeft: {
		display: 'flex',
		flexFlow: 'column',
		minWidth: '150px',
		justifyContent: 'center',
		paddingRight: '1em',
		'@media (max-width: 480px)': {
			minWidth: '80px',
			paddingRight: '0.5em'
		}
	},
	footerRight: {
		display: 'flex',
		flexFlow: 'row',
		paddingLeft: '1em',
		width: '100%',
		justifyContent: 'space-between',
		'@media (max-width: 767px)': {
			width: 'auto'
		},
		'@media (max-width: 480px)': {
			paddingLeft: '0'
		}
	},
	footerNavLeft: {
		display: 'flex',
		flexFlow: 'column',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		'@media (max-width: 767px)': {
			alignItems: 'flex-end'
		}
	},
	footerNavRight: {
		display: 'flex',
		flexFlow: 'column',
		alignItems: 'flex-end',
		'@media (max-width: 767px)': {
			display: 'none'
		}
	},
	footerBrand: {
		width: '128px',
		marginBottom: '0.5em',
		'@media (max-width: 480px)': {
			width: '110px'
		}
	},
	footerTitle: {
		color: '#777777',
		width: '100%',
		'@media (max-width: 420px)': {
			paddingBottom: '8px'
		}
	},
	footerSubtitle: {
		color: '#777777',
		width: '100%'
	},
	footerNavLink: {
		color: '#777777',
		paddingBottom: '8px',
		'@media (max-width: 767px)': {
			textAlign: 'right',
			paddingLeft: '8px'
		}
	},
	footerNavLinkActive: {
		color: 'white'
	}
};

class Footer extends Component {
	render() {
		const {classes, path} = this.props;
		return (
  			<div className={classes.footer}>
  				<div className={classes.footerLeft}>
  				<img alt="Site Logo" src={siteLogo} className={classes.footerBrand}/>
  				<h4 className={classes.footerSubtitle}>ABN 61 130 092 703</h4>
  				</div>

  				<div className={classes.footerRight}>
  					<div className={classes.footerNavLeft}>
  						<Link to="/"><h4 className={classes.footerNavLink + ' ' + (path === "/" ? classes.footerNavLinkActive : '')}>Home</h4></Link>
  						<Link to="/projects"><h4 className={classes.footerNavLink + ' ' + (path === "/projects" ? classes.footerNavLinkActive : '')}>Projects</h4></Link>
  						<Link to="/about"><h4 className={classes.footerNavLink + ' ' + (path === "/about" ? classes.footerNavLinkActive : '')}>About Us</h4></Link>
  						<Link to="/contact"><h4 className={classes.footerNavLink + ' ' + (path === "/contact" ? classes.footerNavLinkActive : '')}>Contact</h4></Link>
  						<a href="https://app.eastwoodproperty.com.au/#/inductions"><h4 className={classes.footerNavLink}>Complete an induction</h4></a>
  						<a href="https://app.eastwoodproperty.com.au"><h4 className={classes.footerNavLink}>Staff / client login</h4></a>
  					</div>
  					<div className={classes.footerNavRight}>
  						<Link to="/"><h4 className={classes.footerNavLink + ' ' + classes.footerNavLinkActive}>Eastwood Property</h4></Link>
  						<a href="https://www.vpm.com.au"><h4 className={classes.footerNavLink}>Versatile Property Maintenance</h4></a>
  						<a href="https://www.eastwoodheritage.com.au"><h4 className={classes.footerNavLink}>Eastwood Heritage</h4></a>
  						<a href="http://www.smartsitegear.com.au"><h4 className={classes.footerNavLink}>Smart Site Gear</h4></a>
  					</div>
  				</div>
  			</div>
		);
	}
}

export default injectSheet(styles)(Footer);
