import React, {Component} from 'react';
import injectSheet from 'react-jss';

const styles = {
	button: {
		border: '3px solid white',
		color: 'white',
		borderRadius: '0px',
		textTransform: 'uppercase',
		fontFamily: '"Montserrat", Helvetica, sans-serif',
		fontSize: '16px',
		'-webkit-transition': '170ms cubic-bezier(.33,0,.2,1)',
		transition: '170ms cubic-bezier(.33,0,.2,1)',
		padding: '.75rem 1.5rem',
		backgroundColor: 'transparent',
		fontWeight: '600',
		lineHeight: 1.25,
		'&:hover': {
			border: '3px solid white',
			color: 'black',
			backgroundColor: 'white',
			transform: 'scale(1.05)'
		}
	},
	dark: {
		color: 'black',
		border: '3px solid black',
		'&:hover': {
			border: '3px solid black',
			color: 'white',
			backgroundColor: 'black',
		}
	}
};

class Button extends Component {
	render() {
		const {children, classes, onClick, dark} = this.props;
		return (
      <button onClick={onClick} className={classes.button + ' ' + (dark ? classes.dark : '')}>{children}</button>
		);
	}
}

export default injectSheet(styles)(Button);
